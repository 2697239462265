import React, { useEffect } from 'react'
import {gsap} from 'gsap'

const BorealisArrow = () => {

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // const tl = new gsap.timeline({ repeat: -1,  })
      gsap.from('.arrow', {
        opacity: 0,
        stagger: 0.5,
        repeat: -1,
        yoyo: true
      })
    }
  })

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.7 61.2">
      <linearGradient id="arrowGrad" gradientUnits="userSpaceOnUse" x1="25.9104" y1="55.8682" x2="51.3857" y2="11.7564" gradientTransform="matrix(1 0 0 -1 0 44)">
        <stop  offset="0" style={{ stopColor: '#00B6F0' }}/>
        <stop  offset="1" style={{ stopColor: '#47B54C' }}/>
      </linearGradient>
      <polygon className="arrow" fill="url(#arrowGrad)" points="35.9,30.5 5.4,0 0,5.4 35.9,41.2 71.7,5.4 66.4,0"/>
      <polygon className="arrow" fill="url(#arrowGrad)" points="35.9,50.5 5.4,20 0,25.4 35.9,61.2 71.7,25.4 66.4,20"/>
    </svg>
  )
}

export default BorealisArrow