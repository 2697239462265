import React from 'react'
import { Container } from '@chakra-ui/react'
import { spacing } from '../theme/themeAlopex'
import BorealisAnimated from '../components/BorealisAnimated'
import BorealisArrow from '../components/BorealisArrow'

const Borealis = () => {
  return (
    <>
      <Container pt={spacing.header} backgroundColor="black" p="50px">
        <BorealisAnimated />
      </Container>
      <Container pt={spacing.header} mb={spacing.header} backgroundColor="gray" p="50px">
        <BorealisArrow />
      </Container>
    </>
  )
}

export default Borealis