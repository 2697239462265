import React, { useEffect } from 'react'
import {gsap} from 'gsap'

const BorealisAnimated = () => {

  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.from('#lettersBorealis', { opacity: 0, y: 50, duration: 1 })
      gsap.from('#lettersBasecamp', { opacity: 0, y: 50, duration: 1, delay: 0.5 })
      gsap.from('#moon', { cy: 676, duration: 2, delay: 1 })
      gsap.from('#auroraPylons polygon', { opacity: 0, y: -50, stagger: -0.2, ease: 'sine.out', duration: 1.4, delay: 2 })
      gsap.to('#auroraPylons polygon', { 
        delay: 3.4,
        duration: 1,
        stagger: {
          y: "-=15",
          each: 0.3,
          from: 'end',
          repeat: -1,
          ease: 'none',
          yoyo: true,
        },
      })
    }
  })

  return (
    <svg style={{ overflow: 'visible' }} version="1.1" id="SVGBorealisLogoAnimated" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 934.9 783.4">
      <g id="auroraPylons">
        <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="690.89" y1="749.69" x2="690.89" y2="446.37" gradientTransform="matrix(1 0 0 -1 0 786)">
          <stop offset="0" style={{ stopColor: '#00B6F0' }} />
          <stop offset="1" style={{ stopColor: '#47B54C' }} />
        </linearGradient>
        <polygon fill="url(#SVGID_4_)" points="672.1,71.4 672.1,72.1 672.1,339.6 709.7,304.5 709.7,303.8 709.7,36.3 		" />

        <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="616.415" y1="713.37" x2="616.415" y2="410.05" gradientTransform="matrix(1 0 0 -1 0 786)">
          <stop offset="0" style={{ stopColor: '#00B6F0' }} />
          <stop offset="1" style={{ stopColor: '#47B54C' }} />
        </linearGradient>
        <polygon fill="url(#SVGID_5_)" points="597.6,107.8 597.6,108.4 597.6,376 635.2,340.8 635.2,340.1 635.2,72.6 		" />

        <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="541.935" y1="677.06" x2="541.935" y2="373.74" gradientTransform="matrix(1 0 0 -1 0 786)">
          <stop offset="0" style={{ stopColor: '#00B6F0' }} />
          <stop offset="1" style={{ stopColor: '#47B54C' }} />
        </linearGradient>
        <polygon fill="url(#SVGID_6_)" points="523.2,144.1 523.2,144.8 523.2,412.3 560.7,377.1 560.7,376.5 560.7,108.9 		" />

        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="467.455" y1="786" x2="467.455" y2="482.68" gradientTransform="matrix(1 0 0 -1 0 786)">
          <stop offset="0" style={{ stopColor: '#00B6F0' }} />
          <stop offset="1" style={{ stopColor: '#47B54C' }} />
        </linearGradient>
        <polygon fill="url(#SVGID_1_)" points="448.7,35.1 448.7,35.8 448.7,303.3 486.2,268.2 486.2,267.5 486.2,0 		" />

        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="392.975" y1="749.69" x2="392.975" y2="446.37" gradientTransform="matrix(1 0 0 -1 0 786)">
          <stop offset="0" style={{ stopColor: '#00B6F0' }} />
          <stop offset="1" style={{ stopColor: '#47B54C' }} />
        </linearGradient>
        <polygon fill="url(#SVGID_2_)" points="374.2,71.4 374.2,72.1 374.2,339.6 411.8,304.5 411.8,303.8 411.8,36.3 		" />

        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="318.495" y1="713.37" x2="318.495" y2="410.05" gradientTransform="matrix(1 0 0 -1 0 786)">
          <stop offset="0" style={{ stopColor: '#00B6F0' }} />
          <stop offset="1" style={{ stopColor: '#47B54C' }} />
        </linearGradient>
        <polygon fill="url(#SVGID_3_)" points="299.7,107.8 299.7,108.4 299.7,376 337.3,340.8 337.3,340.1 337.3,72.6 		" />


        <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="244.025" y1="677.06" x2="244.025" y2="373.74" gradientTransform="matrix(1 0 0 -1 0 786)">
          <stop offset="0" style={{ stopColor: '#00B6F0' }} />
          <stop offset="1" style={{ stopColor: '#47B54C' }} />
        </linearGradient>
        <polygon fill="url(#SVGID_7_)" points="225.2,144.1 225.2,144.8 225.2,412.3 262.8,377.1 262.8,376.5 262.8,108.9 		" />
      </g>

      <g id="lettersBasecamp">
        <defs>
          <mask id="moonMask" maskUnits="userSpaceOnUse">
            <rect x="200" y="255" width="500" height="210" fill="#ffffff"></rect>
          </mask>
        </defs>

        {/* <path id="moon" mask="url(#moonMask)" fill="#FFFFFF" d="M467.5,262.8c-111.5,0-201.9,90.4-201.9,201.9h403.8C669.3,353.2,579,262.8,467.5,262.8z" /> */}
        <circle id="moon" mask="url(#moonMask)" cx="468" cy="466" r="202" fill="#ffffff" />
        <path fill="#31B572" d="M163.1,710.7h12c10.8,0,14.8,5.9,14.8,15.5v3.3c0,10.1-2.9,13.2-6.6,14.5c4.1,1.3,8.1,4.8,8.1,14.4v5.3
          c0,12.7-4.1,18.8-16.2,18.8h-12.1V710.7z M175,741.3c6.4,0,8.4-2.8,8.4-11.4v-3.3c0-6.7-2.5-10-8.8-10h-4.7v24.7H175z
            M169.9,776.6h4.8c7.9,0,10-3.9,10-13.1v-4.9c0-8-3-11.5-9.9-11.5h-4.9L169.9,776.6z" />
        <path fill="#31B572" d="M247.8,758.7l-3.6,23.7h-6.9l11.8-71.7h9.8l12.3,71.7H264l-3.9-23.7H247.8z M259.3,752.4
		      c-2.8-16.6-4.6-29-5.3-35.4h-0.3c-0.2,5.5-2.9,21.8-4.9,35.4H259.3z" />
        <path fill="#31B572" d="M323.5,762.5v4.1c0,7.1,2.5,10.8,7.7,10.8s7.5-3.7,7.5-12.2s-1.8-12-9.3-17.8c-7.7-6-11.5-9.6-11.5-20.1
          c0-9,2.7-17.6,13.5-17.5c10.1,0.1,13.1,6.7,13.1,16v3.2h-6.9v-3c0-6.1-1.2-10.2-6.4-10.2s-6.5,4.6-6.5,10.4c0,7.1,1.4,9.5,8,14.6
          c9.8,7.5,12.9,11.6,12.9,23.4c0,10.1-2.7,19.2-14.5,19.2c-11.2,0-14.5-7.4-14.5-16.8v-4.1L323.5,762.5z" />
        <path fill="#31B572" d="M417.4,747.2h-16.1v29h18.2l-1,6.2h-24.1v-71.7h23.9v6.3h-17.1v24h16.1V747.2z" />
        <path fill="#31B572" d="M494.5,762v2.4c0,9.3-1.7,19-14.2,19c-12.2,0-14.2-8.9-14.2-19.4v-36.5c0-11.6,4.9-17.7,14.3-17.7
          c11.7,0,13.7,8.5,13.7,17.7v2.8h-7v-3.1c0-5.9-0.9-11.2-6.9-11.2c-5.8,0-7.2,4.7-7.2,11.4v37c0,7.7,1.7,12.8,7.4,12.8
          c6,0,7-5.2,7-12.3V762H494.5z" />
        <path fill="#31B572" d="M550.7,758.7l-3.7,23.7h-6.9l11.9-71.7h9.8l12.3,71.7h-7.2l-3.9-23.7H550.7z M562.1,752.4
		      c-2.8-16.6-4.6-29-5.3-35.4h-0.3c-0.2,5.5-2.9,21.8-4.9,35.4H562.1z" />
        <path fill="#31B572" d="M657.3,751.7c0-13.3,0.2-27.3,0.4-34.8h-0.1c-1.8,11.9-7.6,44-11.7,65.5h-6.8c-3-18.3-9-52.8-10.7-65.6
          h-0.2c0.2,8.4,0.5,24.6,0.5,36.9v28.7h-6.4v-71.7h10.2c4.2,21.9,9.2,49.5,10.2,58.5h0.1c1.2-8.7,7-37.9,11.2-58.5h10v71.7h-6.7
          L657.3,751.7z" />
        <path fill="#31B572" d="M714.4,710.7h12.5c10.5,0,15.5,5.4,15.5,16.6v4.4c0,10.5-3,18.6-15.9,18.6h-5.2v32.1h-6.9V710.7z
			    M721.3,744.4h4.5c8.5,0,9.7-5.2,9.7-12.8v-3.9c0-5.9-1.4-11.2-9-11.2h-5.2L721.3,744.4z" />
      </g>
      <g id="lettersBorealis">
        <path fill="#FFFFFF" d="M67.1,582.2c8.6-3.3,17.7-11,17.7-37v-6.9c0-27.1-12.6-42-45.7-42H0v184.5h41.7
          c33.4,0,46.8-16.6,46.8-47.5v-14.1C88.5,592.4,75.6,585.5,67.1,582.2z M28.5,519.8h9.1c14,0,19.7,5.8,19.7,21.3v7.2
          c0,18.2-5.1,23.2-21.4,23.2h-7.4L28.5,519.8z M59.3,632.4c0,18-4,25.1-21.1,25.1h-9.7v-63.2H36c18.8,0,23.4,7.7,23.4,25.7
          L59.3,632.4z" />
        <path fill="#FFFFFF" d="M174.3,493.5c-27.6,0-45.3,16.3-45.3,51.6v86.7c0,34.5,13.5,51.6,43.4,51.6c31.5,0,46.4-16.8,46.4-52.2
          v-86.1C218.8,509.8,202.2,493.5,174.3,493.5z M189.8,634.9c0,16-5,22.9-16,22.9c-10.2,0-15.7-7.2-15.7-22.6v-92.8
          c0-15.5,5.5-22.9,15.7-22.9c11.6,0,16,8,16,22.6V634.9z" />
        <path fill="#FFFFFF" d="M330.2,586.3c6.9-3,18.8-10.2,18.8-38.7v-6.9c0-33.1-16.6-44.5-44.7-44.5h-39.5v184.4h28.4v-75.8h6.6
          c16.6,0,19.3,5.2,19.3,27.6c0,13.5-0.3,37.2,1.9,48.2H352c-1.9-11-5-41.1-5-56.6C347,599,340.7,591.3,330.2,586.3z M320.3,551
          c0,21.5-5.5,27.4-20.4,27.4h-6.6v-58.6h8c16.3,0,19,9.1,19,24.4L320.3,551z" />
        <polygon fill="#FFFFFF" points="396.1,680.7 475.7,680.7 475.7,654.5 424.6,654.5 424.6,596.5 465.1,596.5 465.1,570.3 
		      424.6,570.3 424.6,522.8 477.2,522.8 477.2,496.3 396.1,496.3 		" />
        <path fill="#FFFFFF" d="M544.1,496.3l-29.8,184.4h29c0,0,1.9-14,4.5-33.6h32.8c2.7,19.7,4.6,33.6,4.6,33.6h29.8l-30.9-184.4H544.1
		      z M551.1,621.8c5.4-41,11.7-89.9,12-98.5h1.1c1.2,11.5,7.6,58.7,13,98.5H551.1z" />
        <polygon fill="#FFFFFF" points="687.1,496.3 658.6,496.3 658.6,680.7 733.5,680.7 733.5,655.3 687.1,655.3 		" />
        <rect x="775.4" y="496.3" fill="#FFFFFF" width="28.4" height="184.4" />
        <path fill="#FFFFFF" d="M898.7,569.5C882.5,557.6,880,551.2,880,538c0-12.2,2.5-20.2,11.9-20.2s11.6,7.7,11.6,20.7v9.1h28.1v-9.9
          c0-20.2-5.2-44.5-39.2-44.5c-33.4,0-40.9,24.6-40.9,48.9c0,27.3,10.8,37.3,30.7,53c19.6,15.7,24,21.8,24,41.7
          c0,11.9-3,22.4-14.1,22.4c-10.8,0-14.9-7.7-14.9-21.3v-11.6h-28.4v11c0,24.3,8.3,46.1,42.5,46.1c34.5,0,43.6-24.8,43.6-51.1
          C934.9,601.5,926.3,589.9,898.7,569.5z" />
      </g>
    </svg>

  )
}

export default BorealisAnimated